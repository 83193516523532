<template>
    <v-row>
        <v-col id="codigo-pago-reposicion" cols="12" :class="center?'text-center':''" class="pb-0">
            <h5 class="grey--text pb-2">Código CPT generado para su {{titulo}}</h5>
            <h2>{{modelo.codigo}} <v-icon v-show="false" @click="" class="ml-5">mdi-printer</v-icon></h2> 
            <p>Monto a pagar: {{modelo.monto}} Bs.</p>
            <p>Estado Código: 
                <v-chip
                text-color="white"
                small
                :color="getColorEstado(modelo.estado)"
                >
                    {{modelo.estado}}
                </v-chip>
            </p>
        </v-col>
        <v-col cols="12" v-if="modelo.estado=='CREADO'" class="py-0">
            <v-alert v-if="!verificarVigencia()" color="primary" outlined :value="true" text class="body-2">
                Utilice el código de pago CPT para realizar el pago a través de Uninet o en las ventanillas 
                de cualquier agencia del Banco Unión. Este código es valido hasta: <b>{{formatDateText(modelo.fechaVencimiento)}}</b>
            </v-alert>
            <v-alert v-else color="error" outlined :value="true" text class="body-2">
                El código de pago CPT vencio el <b>{{formatDateText(modelo.fechaVencimiento)}}</b>, para generar un nuevo codigo
                <v-btn :loading="loading" color="success" rounded small @click="regenerarCpt()">presiona aqui</v-btn>
            </v-alert>
        </v-col>
    </v-row>     
</template>
<script>
import mixinDateTime from "@/mixins/datetime" 
export default {
    props:{
        cpt:{
            type: Object,
            default: ()=>{
                return {
                    codigo:'XXXX-XXXX-XXXXX',
                    fechaInicio: '2021-02-01',
                    fechaVencimiento:'',
                    estado:'CREADO',
                    monto:0
                }
            }
        },
        titulo:{
            type: String,
            default: 'Trámite'
        },
        center:{
            type: Boolean,
            default: false
        }
    },
    mixins: [mixinDateTime],
    data(){
        return{
            loading:false,
            modelo :this.cpt
        }
    },
    methods:{
        getColorEstado(estado){
            return estado == 'CREADO'?'grey':estado == 'PAGADO'?'secondary lighten-2':'success'
        },
        verificarVigencia(){
            const date = new Date();
            const dateCpt = new Date(`${this.modelo.fechaVencimiento} 23:00:00`);
            return this.modelo.estado === 'CREADO' && date>dateCpt
        },
        regenerarCpt(){
            this.loading=true
            this.$http.put(`${this.$apiUrl}abogado-ciudadano/codigo-pago-tramite/${this.modelo.codigo}/regenerar`).then(response=>{
                this.loading = false
                this.modelo=response.data.data.cpt
            }).catch(error=>{
                this.loading = false
                //  this.$notify({
                //     group:'foo',
                //     type:'error',
                //     title:'ERROR CPT',
                //     text:error.message
                // })
                console.log(error)
            })
        }
    }
}
</script>